import { defineMessages } from 'react-intl';

const internalScope = 'links.internal';
const externalScope = 'links.external';
const socialScope = 'links.social';
const baseUrl = 'https://circleci.com';
const internalLinks = {
  signup: {
    id: `${internalScope}.signup`,
    defaultMessage: '/signup/',
  },
  vcsAuthorize: {
    id: `${internalScope}.vcsAuthorize`,
    defaultMessage: '/vcs-authorize/',
  },
};
const socialLinks = {
  blogFeed: {
    id: `${socialScope}.blogFeed`,
    defaultMessage: `${baseUrl}/blog/feed.xml`,
  },
  facebook: {
    id: `${socialScope}.facebook`,
    defaultMessage: 'https://www.facebook.com/circleci',
  },
  twitter: {
    id: `${socialScope}.twitter`,
    defaultMessage: 'https://twitter.com/circleci',
  },
  github: {
    id: `${socialScope}.github`,
    defaultMessage: 'https://github.com/circleci',
  },
  linkedIn: {
    id: `${socialScope}.linkedIn`,
    defaultMessage: 'https://www.linkedin.com/company/circleci',
  },
};
const legalLinks = {
  termsOfService: {
    id: `${externalScope}.termsOfService`,
    defaultMessage: `${baseUrl}/terms-of-service/`,
  },
  termsOfUse: {
    id: `${externalScope}.termsOfUse`,
    defaultMessage: `${baseUrl}/legal/terms-of-use/`,
  },
  privacyPolicy: {
    id: `${externalScope}.privacyPolicy`,
    defaultMessage: `${baseUrl}/legal/privacy/`,
  },
  cookiePolicy: {
    id: `${externalScope}.cookiePolicy`,
    defaultMessage: `${baseUrl}/legal/cookie-policy/`,
  },
  security: {
    id: `${externalScope}.security`,
    defaultMessage: `${baseUrl}/security/`,
  },
  recaptchaTerms: {
    id: `${externalScope}.recaptchaTerms`,
    defaultMessage: 'https://policies.google.com/terms',
  },
  recaptchaPrivacy: {
    id: `${externalScope}.recaptchaPrivacy`,
    defaultMessage: 'https://policies.google.com/privacy',
  },
};
const productLinks = {
  product: {
    id: `${externalScope}.product`,
    defaultMessage: `${baseUrl}/product/`,
  },
  pricing: {
    id: `${externalScope}.pricing`,
    defaultMessage: `${baseUrl}/pricing/`,
  },
  integrations: {
    id: `${externalScope}.integrations`,
    defaultMessage: `${baseUrl}/integrations/`,
  },
  openSource: {
    id: `${externalScope}.openSource`,
    defaultMessage: `${baseUrl}/open-source/`,
  },
  enterprise: {
    id: `${externalScope}.enterprise`,
    defaultMessage: `${baseUrl}/enterprise/`,
  },
  changeLog: {
    id: `${externalScope}.changeLog`,
    defaultMessage: `${baseUrl}/changelog/`,
  },
};

const supportLinks = {
  docs: {
    id: `${externalScope}.docs`,
    defaultMessage: `${baseUrl}/docs/`,
  },
  support: {
    id: `${externalScope}.support`,
    defaultMessage: `https://support.circleci.com/hc/en-us/`,
  },
  forum: {
    id: `${externalScope}.forum`,
    defaultMessage: `https://discuss.circleci.com/`,
  },
  systemStatus: {
    id: `${externalScope}.systemStatus`,
    defaultMessage: `https://status.circleci.com/`,
  },
  featureRequests: {
    id: `${externalScope}.featureRequests`,
    defaultMessage: `https://ideas.circleci.com/`,
  },
  premiumSupport: {
    id: `${externalScope}.premiumSupport`,
    defaultMessage: `${baseUrl}/support/plans/`,
  },
};

const resourceLinks = {
  blog: {
    id: `${externalScope}.blog`,
    defaultMessage: `${baseUrl}/blog/`,
  },
  customers: {
    id: `${externalScope}.customers`,
    defaultMessage: `${baseUrl}/customers/`,
  },
  ebooks: {
    id: `${externalScope}.ebooks`,
    defaultMessage: `${baseUrl}/resources/#ebooks`,
  },
  reports: {
    id: `${externalScope}.reports`,
    defaultMessage: `${baseUrl}/resources/#reports`,
  },
  webinars: {
    id: `${externalScope}.webinars`,
    defaultMessage: `${baseUrl}/resources/webinars/`,
  },
  videos: {
    id: `${externalScope}.videos`,
    defaultMessage: `${baseUrl}/resources/#videos`,
  },
};
const companyLinks = {
  about: {
    id: `${externalScope}.about`,
    defaultMessage: `${baseUrl}/about/`,
  },
  careers: {
    id: `${externalScope}.careers`,
    defaultMessage: `${baseUrl}/careers/`,
  },
  team: {
    id: `${externalScope}.team`,
    defaultMessage: `${baseUrl}/about/team/`,
  },
  contact: {
    id: `${externalScope}.contact`,
    defaultMessage: `${baseUrl}/contact/`,
  },
  partners: {
    id: `${externalScope}.partners`,
    defaultMessage: `${baseUrl}/partners/`,
  },
  newsroom: {
    id: `${externalScope}.newsroom`,
    defaultMessage: `${baseUrl}/newsroom/`,
  },
};
const gettingStartedLinks = {
  continuousIntegration: {
    id: `${externalScope}.continuousIntegration`,
    defaultMessage: `${baseUrl}/continuous-integration/`,
  },
  docs2: {
    id: `${externalScope}.docs2`,
    defaultMessage: `${baseUrl}/docs/2.0/`,
  },
};

const loginLinks = {
  login: {
    id: `${externalScope}.login`,
    defaultMessage: `${baseUrl}/authentication/login?f=gho`,
  },
  loginPublic: {
    id: `${externalScope}.loginPublic`,
    defaultMessage: `${baseUrl}/authentication/login?f=gho&s=public`,
  },
  loginBitBucket: {
    id: `${externalScope}.loginBitBucket`,
    defaultMessage: `${baseUrl}/authentication/login?f=bbo`,
  },
  authSignUp: {
    id: `${externalScope}.authSignUp`,
    defaultMessage: `${baseUrl}/authentication/signup/`,
  },
  authLogin: {
    id: `${externalScope}.authLogin`,
    defaultMessage: `${baseUrl}/authentication/login/`,
  },
};
export default defineMessages({
  root: {
    id: `${externalScope}.root`,
    defaultMessage: `${baseUrl}/`,
  },
  ...legalLinks,
  ...internalLinks,
  ...socialLinks,
  ...companyLinks,
  ...productLinks,
  ...resourceLinks,
  ...supportLinks,
  ...gettingStartedLinks,
  ...loginLinks,
});
